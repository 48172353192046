import axios from "axios";
import { apiUrl } from "./config";
import { useEffect, useState, useRef } from "react";
import Navbar from "./Components/compNavbar";

const Body = () => {
    
    const modalDeleteRef = useRef(null)
    const modalAddRef = useRef(null)
    const [allInfo, setAllInfo] = useState()
    const [idForDelete, setIdForDelete] = useState()
    const [addFromData, setAddFromData] = useState({
        title: "",
        username: "",
        password: "",
        confirm_password: "",
        site_url: "",
        u_field: "",
        p_field: "",
        extra: ""
    })

    const FetchAllPassInfo = () => {
        axios.get(apiUrl + "/info").then(res => {
            console.log(res.data)
            setAllInfo(res.data)
        }).catch(err => {
            console.log(err)
        })
    }
    const handleClearField = (e, fieldName) => {
        console.log("Clearing field: " + fieldName);
        setAddFromData({
            ...addFromData,
            [fieldName]: ""
        })
        e.preventDefault();
    }
    const clearAllField = () => {
        setAddFromData({
            title: "",
            username: "",
            password: "",
            confirm_password: "",
            site_url: "",
            u_field: "",
            p_field: "",
            extra: ""
        })
    }
    const handleAddInput = (e) => {
        e.preventDefault();
        setAddFromData({ ...addFromData, [e.target.name]: e.target.value })
    }
    const handleAddInfo = () => {
        modalAddRef.current.click();
        const { confirm_password, ...data } = addFromData
        axios.post(apiUrl + "/info", data).then(res => {
            console.log(res.data)
            clearAllField();
            FetchAllPassInfo();
        }).catch(err => {
            console.log(err)
        })
    }
    const handleDeleteInfo = () => {
        console.log("Deleting id: " + idForDelete);
        modalDeleteRef.current.click();
        axios.delete(apiUrl + "/info/" + idForDelete).then(res => {
            console.log(res.data)
            FetchAllPassInfo();
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        FetchAllPassInfo();
    }, [])

    useEffect(() => {
        addFromData && console.log("Changed Form Add >>>", addFromData)
    }, [addFromData])
    useEffect(() => {
        idForDelete && console.log("Selected Delete id: " + idForDelete)
    }, [idForDelete])

    const CompCancelBtn = (props) => {
        return (
            <button className="btn btn-sm border border-start-0 m-0 p-0 pe-1" type="button" id="title-clr" onClick={props.handle}><img className="p-0 m-0" width="35px" src="https://img.icons8.com/external-anggara-flat-anggara-putra/128/000000/external-delete-user-interface-basic-anggara-flat-anggara-putra.png" /></button>
        )
    }
    const CompInput = (props) => {
        return (
            <input autoComplete="off" type={props.type} className="form-control border-end-0" id={props.id} name={props.id} value={props.value} onChange={props.handle} placeholder={props.placeholder} />
        )
    }
    const CompInputGroup = (props) => {
        return (
            <div className="input-group mb-2">
                {CompInput(props)}
                {/* <CompInput {...props} /> */}
                {CompCancelBtn({ handle: (e) => handleClearField(e, props.id) })}
                {/* <CompCancelBtn /> */}
            </div>
        )
    }

    return (
        <>
            <div className="container">
                <div class="position-fixed bottom-0 end-0 translate-middle p-0" type="button" data-bs-toggle="modal" data-bs-target="#addNewModal"><img width="50px" className="on-hover-zoom shadow-sm rounded-circle m-0 p-0" src="https://img.icons8.com/color/144/000000/plus--v1.png" /></div>

                <div className="d-flex justify-content-center my-1">
                    <div className="modal fade" id="addNewModal" ref={modalAddRef} data-bs-keyboard="false" tabIndex="-1" aria-labelledby="AddNewModal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header p-1 px-3">
                                    <h5 className="modal-title" id="AddNewModal">Add New</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {/* <CompInputGroup type="text" placeholder="Enter Title" id="title" name="title" value={addFromData?.title} handle={handleAddInput}/> */}
                                    {CompInputGroup({ type: "text", id: "title", value: addFromData?.title, handle: handleAddInput, placeholder: "Enter Title" })}
                                    <div className="input-group mb-2">
                                        <button className="btn btn-outline-light border text-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">https://</button>
                                        <ul className="dropdown-menu">
                                            <li className="ms-2 bg-light mb-1 shadow-sm">https://</li>
                                            <li className="ms-2 bg-light mb-1 shadow-sm">http://</li>
                                        </ul>
                                        {CompInput({ type: "text", placeholder: "Enter URL", id: "site_url", value: addFromData?.site_url, handle: handleAddInput })}
                                        {CompCancelBtn({ handle: (e) => handleClearField(e, "site_url") })}
                                    </div>
                                    {CompInputGroup({ type: "text", id: "username", value: addFromData?.username, handle: handleAddInput, placeholder: "Enter Username" })}
                                    {CompInputGroup({ type: "password", id: "password", value: addFromData?.password, handle: handleAddInput, placeholder: "Enter Password" })}
                                    {CompInputGroup({ type: "password", id: "confirm_password", value: addFromData?.confirm_password, handle: handleAddInput, placeholder: "Confirm Password" })}

                                    <div className="d-flex justify-content-center mb-2">
                                        <img className="mx-1" width="35px" data-bs-toggle="collapse" href="#fUser" role="button" aria-expanded="false" aria-controls="fUser" src="https://img.icons8.com/fluency/48/000000/user-male-circle.png" />
                                        <img className="mx-1" width="35px" data-bs-toggle="collapse" data-bs-target="#fPass" aria-expanded="false" aria-controls="fPass" src="https://img.icons8.com/external-others-inmotus-design/67/000000/external-Password-round-icons-others-inmotus-design-8.png" />
                                        <img className="mx-1" width="35px" data-bs-toggle="collapse" data-bs-target="#d_extra" aria-expanded="false" aria-controls="d_extra" src="https://img.icons8.com/color/48/000000/grey.png" />
                                    </div>
                                    <div className="collapse multi-collapse" id="fUser">
                                        {CompInputGroup({ type: "text", id: "u_field", value: addFromData?.u_field, handle: handleAddInput, placeholder: "Enter ID for User Field" })}
                                    </div>
                                    <div className="collapse multi-collapse" id="fPass">
                                        {CompInputGroup({ type: "text", id: "p_field", value: addFromData?.p_field, handle: handleAddInput, placeholder: "Enter ID for Password Field" })}
                                    </div>
                                    <div className="collapse multi-collapse" id="d_extra">
                                        {CompInputGroup({ type: "text", id: "extra", value: addFromData?.extra, handle: handleAddInput, placeholder: "Whatever" })}
                                    </div>
                                </div>
                                <div className="modal-footer p-1">
                                    <button type="button" className="btn btn-sm btn-danger" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-sm btn-success" onClick={handleAddInfo}>Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Delete Confirm Dialogue >> S */}
                    <div className="modal fade" id="deleteDialouge" ref={modalDeleteRef} data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteDialouge" aria-hidden="true">
                        <div className="modal-dialog modal-sm modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="d-flex flex-column align-items-center">
                                        <h5>Are You Sure?</h5>
                                        <small>You want to delete this Info?</small>
                                    </div>
                                    <div className="d-flex justify-content-center p-0 mt-3 ">
                                        <button type="button" className="btn btn-sm btn-outline-primary me-2" data-bs-dismiss="modal">No</button>
                                        <button type="button" className="btn btn-sm btn-outline-danger ms-2" onClick={handleDeleteInfo}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Delete Confirm Dialogue >> E  */}


                    {/* <div type="button" data-bs-toggle="modal" data-bs-target="#addNewModal" className="rounded rounded-3 bg-success p-1 shadow d-flex justify-content-center align-items-center">
                        <img width="30px" src="https://img.icons8.com/color/48/000000/plus--v3.png" />
                        <p className="m-0 text-light px-1">Add New</p>
                    </div> */}
                </div>
                {allInfo && allInfo.map((info, index) => {
                    return (
                        <div key={index} className="bg-light rounded rounded-3 px-1 mb-3 shadow-sm">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between bg-success rounded-top py-1">
                                    <h6 className="d-flex justify-content-center m-0 text-light">{info.title}</h6>
                                    <a className="d-flex justify-content-center" href={info.site_url} target="_blank" rel="noopener noreferrer"><img width="20px" src="https://img.icons8.com/fluency-systems-regular/48/ffffff/external-link-squared.png" /></a>
                                </div>
                                <div className="col-12 bg-light pt-2 d-flex align-items-center justify-content-between">
                                    <p className="m-0">{info.username}</p>
                                    <img width="20px" src="https://img.icons8.com/external-compact-zufarizal-robiyanto/32/000000/external-copy-compact-ui-essential-vol2-compact-zufarizal-robiyanto.png" />
                                </div>
                                <div className="col-12 bg-light py-1 d-flex align-items-center justify-content-between">
                                    <p className="m-0">{info.password}</p>
                                    <img width="20px" src="https://img.icons8.com/external-compact-zufarizal-robiyanto/32/000000/external-copy-compact-ui-essential-vol2-compact-zufarizal-robiyanto.png" />
                                </div>
                                <div className="col-12 bg-light py-1 d-flex align-items-center justify-content-center">
                                    <img className="mx-2 on-hover-color-primary on-hover-zoom rounded-circle" width="22px" src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-edit-miscellaneous-kiranshastry-lineal-kiranshastry.png" />
                                    
                                    <img className="mx-2 on-hover-color-danger on-hover-zoom rounded-circle" width="22px" data-bs-toggle="modal" data-bs-target="#deleteDialouge" onClick={()=>setIdForDelete(info.id)} src="https://img.icons8.com/carbon-copy/100/000000/delete-forever--v1.png" />
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </>
    );
}

const Home = () => {
    return (
        <>
            <Navbar />
            <Body />
        </>
    );
}

export default Home;