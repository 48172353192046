import axios from "axios";
import { apiUrl } from "../config";
import { useEffect, useState } from "react";

const Navbar = () => {
    const [titles, setTitles] = useState([])

    const FetchAllPassTitle = () => {
        axios.get(apiUrl + "/info/title").then(res => {
            console.log(res.data)
            setTitles(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        FetchAllPassTitle();
    }, [])

    return (
        <>
            <div className="container bg-light shadow-sm mb-2 p-2 sticky-top">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-5">
                        <h1 className="m-0 p-0 text-success fw-bold text-opacity-75">FFPK</h1>
                    </div>
                    <div className="col-7">
                        <input className="form-control" list="datalistOptions" id="exampleDataList" placeholder="Search..." />
                        <datalist id="datalistOptions">
                            {titles && titles.map((title, index) => {
                                return (
                                    <option key={index} value={title} />
                                )
                            })}
                        </datalist>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Navbar