import React, { useEffect, useRef, useState } from "react";
import './Login.css';
import axios from "axios";
import { apiUrl } from "./config";
import Home from "./Home";

const Login = () => {
    const passRef = useRef(null)
    const [pass, setPass] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        document.title = "FFPK - Login"
        // passRef.current.focus()
    }, [])

    const SubmitEventHandler = () => {
        if (pass === '123') {
            axios.post(apiUrl + "/loginlog").then(res => {
                console.log(res.data)
                res.data.token && setIsLoggedIn(true);
            }).catch(err => {
                console.log(err)
            })
        }
    }
    const HandleKeyDown = (event) => {
        event.key === 'Enter' && SubmitEventHandler();

    }


    return (
        <>
            {isLoggedIn ? <>
                <Home />
            </> : <>
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="input-group justify-content-center">
                            <input type={"password"} className={"form-control c-pass-field"} placeholder={"Password"}
                                onChange={e => setPass(e.target.value)} onKeyDown={HandleKeyDown} ref={passRef} />
                            <button className="btn btn-primary" type="button" onClick={SubmitEventHandler}>Enter</button>
                        </div>
                    </div>
                </div></>}

        </>
    );
}

export default Login;